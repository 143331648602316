import React, {FC} from 'react'
import {Routes, Route, BrowserRouter} from 'react-router-dom'
import {useAuthenticator} from '@aws-amplify/ui-react'
import Landing from "../pages/Landing";
import {Dashboard} from "../pages/Dashboard";
import {MasterLayout} from "../Layout/MasterLayout";



const AppRoutes: FC = () => {
  const {authStatus} = useAuthenticator((context) => [context.authStatus])
    
  return (
    <BrowserRouter>
      <Routes>
          {authStatus === 'authenticated' ? (
            <>
                    <Route element={<MasterLayout  />}>
                        <Route path='/' element={<Dashboard />} />
                    </Route>
            </>
          ) : (
            <>
              <Route path='/' element={<Landing  />} />
              <Route path='/*' element={<Landing />} />
            </>
          )}
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
